import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import { cn } from '@/gf/modules/utils'
import { TdHTMLAttributes, ThHTMLAttributes } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import { useRfqContext } from '../context'

export const Th = ({ className, ...props }: ThHTMLAttributes<HTMLTableCellElement>) => (
  <th
    className={cn(
      'px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap text-left',
      className
    )}
    {...props}
  />
)

export const Td = ({ className, ...props }: TdHTMLAttributes<HTMLTableCellElement>) => (
  <td className={cn('text-sm px-6 py-3 align-top text-left', className)} {...props} />
)

const Shipment = () => {
  const shipmentId = useMatch('/rfqs/:rfqId/shipments/:shipmentId')?.params.shipmentId
  const { rfq } = useRfqContext()
  const navigate = useNavigate()
  const close = () => navigate(`/rfqs/${rfq.id}`, { replace: true })
  const shipment = rfq.quote?.shipments.find((s) => s.id === shipmentId)

  if (!shipment) return null

  return (
    <Modal open onClose={close}>
      <div className="p-8 pt-10">
        <CloseModalButton onClick={close} className="absolute top-2 right-2" />

        <div className="rounded-lg border overflow-hidden">
          <table className="divide-y-1 divide-gray-200 w-full">
            <thead className="bg-gray-50">
              <tr>
                <Th>Part number</Th>
                <Th>Description</Th>
                <Th className="text-center">Shipped quantity</Th>
              </tr>
            </thead>

            <tbody className="divide-y-1 divide-gray-200">
              {shipment.items.map((item) => (
                <tr key={item.id}>
                  <Td>{item.lineItem.productMpn}</Td>
                  <Td>{item.lineItem.productName}</Td>
                  <Td className="text-center">{item.quantity}</Td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  )
}

export default Shipment
