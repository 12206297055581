import Action from '@/gf/components/Action'
import DateInput from '@/gf/components/inputs/Date'
import Link from '@/gf/components/Link'
import { SimpleTooltip } from '@/gf/components/next/Tooltip'
import Select from '@/gf/components/Select'
import { Tbody, Th, Tr } from '@/gf/components/Table'
import Money from '@/gf/modules/Money'
import Time from '@/gf/modules/Time'
import { cn } from '@/gf/modules/utils'
import { ExternalLinkIcon } from '@heroicons/react/solid'
import { times } from 'lodash'
import { HTMLAttributes, ReactNode, TdHTMLAttributes, useEffect, useState } from 'react'
import { To, useNavigate } from 'react-router-dom'
import {
  GpTransactionType,
  StripeAccountCapabilityStatus,
  useCreateStripeAccountLoginLinkMutation,
  useTransactionsQuery,
  useWalletQuery,
} from '../_gen/gql'
import Frame from '../components/Frame'
import useSession from '../hooks/useSession'
import Type from './Wallet/Type'
import usePagination from './Wallet/usePagination'
import useParams from './Wallet/useParams'
import Id from '@/gf/modules/Id'

const Pager = ({ path, children }: { path: To | null; children: ReactNode }) =>
  path ? <Link.S to={path}>{children}</Link.S> : <Action.S disabled>{children}</Action.S>

const Thead = ({ ...props }: HTMLAttributes<HTMLTableSectionElement>) => <thead {...props} />

const Td = (props: TdHTMLAttributes<HTMLTableCellElement>) => (
  <td
    {...props}
    className={cn('align-middle text-sm whitespace-nowrap px-6 py-3', props.className)}
  />
)

const Ghost = ({ className, ...props }: Omit<HTMLAttributes<HTMLDivElement>, 'children'>) => (
  <div className={cn('bg-gray-200 animate-pulse rounded-lg', className)} {...props}>
    &nbsp;
  </div>
)

const limit = 10

const breadcrumbs = {
  copy: 'Back to Dashboard',
  crumbs: [{ name: 'Wallet', href: '/wallet' }],
}

const toType = (type: string | undefined) => {
  if (type === 'Payment') return GpTransactionType.Payment
  if (type === 'Refund') return GpTransactionType.PaymentRefund
  if (type === 'Payout') return GpTransactionType.Payout
  return null
}

const ths = [
  <Th key="date">Date</Th>,
  <Th key="customer">Customer</Th>,
  <Th key="type">Type</Th>,
  <Th key="amount" className="text-right">
    Amount
  </Th>,
  <Th key="fee" className="text-right">
    Fee
  </Th>,
  <Th key="net" className="text-right">
    Net
  </Th>,
  <Th key="order">Order</Th>,
]

const Wallet = () => {
  const { storeId } = useSession()
  const { params, updateParams } = useParams()
  const [createStripeAccountLoginLink] = useCreateStripeAccountLoginLinkMutation()
  const [visitingStripeDashboard, setVisitingStripeDashboard] = useState(false)
  const { store } = useWalletQuery({ variables: { storeId } }).data || {}
  const navigate = useNavigate()

  useEffect(() => {
    if (
      store &&
      store.stripeAccount?.capabilities.transfers !== StripeAccountCapabilityStatus.Active
    )
      navigate('/setup/gearflow-supplier-network')
  }, [!store])

  const paginatedTxns = useTransactionsQuery({
    variables: {
      storeId,
      limit,
      startingAfter: params.startingAfter || null,
      endingBefore: params.endingBefore || null,
      type: toType(params.type),
      createdAtGte: params.fromDate || null,
      createdAtLte: params.toDate || null,
    },
  }).data?.store?.paginatedTransactions

  const { prevPath, nextPath } = usePagination(paginatedTxns)

  const visitStripeDashboard = async () => {
    setVisitingStripeDashboard(true)
    const { data } = await createStripeAccountLoginLink({ variables: { storeId } })
    window.open(data?.createStripeAccountLoginLink, '_blank')
    setVisitingStripeDashboard(false)
  }

  return (
    <Frame breadcrumbs={breadcrumbs}>
      <div className="flex flex-col px-2 sm:px-4 lg:px-8 pt-2 pb-4 space-y-8">
        <div className="flex flex-wrap justify-between items-end gap-x-8 gap-y-4">
          <h2 className="text-xl">Wallet</h2>

          <div className="flex flex-wrap items-center gap-x-8 gap-y-4 text-gray-700">
            <SimpleTooltip text="Available for the next payout">
              <div className="flex gap-2 items-center">
                <span>Available:</span>

                <span
                  className={cn(
                    store?.stripeAccount && Money.isPos(store.stripeAccount.balance.available)
                      ? 'text-green-700'
                      : 'text-gray-700'
                  )}
                >
                  {store?.stripeAccount ? (
                    Money.format(store.stripeAccount.balance.available)
                  ) : (
                    <Ghost className="w-24" />
                  )}
                </span>
              </div>
            </SimpleTooltip>

            <SimpleTooltip text="Available for payout soon">
              <div className="flex gap-2 items-center">
                <span>Pending:</span>

                <span
                  className={cn(
                    store?.stripeAccount && Money.isPos(store.stripeAccount.balance.available)
                      ? 'text-yellow-600'
                      : 'text-gray-700'
                  )}
                >
                  {store?.stripeAccount ? (
                    Money.format(store.stripeAccount.balance.available)
                  ) : (
                    <Ghost className="w-24" />
                  )}
                </span>
              </div>
            </SimpleTooltip>

            <div className="flex gap-2 items-center">
              <span>Payouts:</span>

              {store?.stripeAccount ? (
                store.stripeAccount.payoutSchedule
              ) : (
                <Ghost className="w-24" />
              )}
            </div>

            <div>
              <Action.T onClick={visitStripeDashboard} className="flex gap-1 items-center">
                <span>Stripe Dashboard</span>

                {visitingStripeDashboard ? (
                  <div className="animate-spin-slow border-t-blue-500 rounded-full border-2 border-gray-200 h-5 w-5 shrink-0" />
                ) : (
                  <ExternalLinkIcon className="w-5 h-5 shrink-0" />
                )}
              </Action.T>
            </div>
          </div>
        </div>

        <div className="border-1 border-gray-300 rounded-md bg-gray-50">
          <div className="flex gap-x-8 gap-y-2 items-center flex-wrap py-4 px-6">
            <div className="flex gap-2 items-center text-sm">
              <div>Type:</div>

              <Select
                options={[
                  { id: '', display: 'All' },
                  { id: 'Payment', display: 'Payment' },
                  { id: 'Refund', display: 'Refund' },
                  { id: 'Payout', display: 'Payout' },
                ]}
                currentId={params.type || undefined}
                onChange={(t) => updateParams({ type: t === '' ? undefined : t })}
              />
            </div>

            <div className="flex gap-2 items-center text-sm">
              <div>Dates:</div>

              <DateInput
                onChange={(fromDate) => updateParams({ fromDate })}
                value={params.fromDate}
              />

              <span>to</span>
              <DateInput onChange={(toDate) => updateParams({ toDate })} value={params.toDate} />
            </div>
          </div>

          <div className="overflow-auto">
            <table className="divide-y divide-gray-200 min-w-full">
              <Thead>{ths}</Thead>

              <Tbody className="bg-white">
                {paginatedTxns?.transactions.map((txn) => (
                  <Tr key={txn.id}>
                    <Td>{Time.formatDateTimeNoYear(txn.createdAt)}</Td>

                    <Td>
                      {txn.storeOrder?.request?.user.organization &&
                        txn.storeOrder?.request?.user.organization.name}
                    </Td>

                    <Td>
                      <Type type={txn.type} />
                    </Td>

                    <Td className="text-right">{Money.format(txn.amount)}</Td>

                    <Td className="text-right">
                      {(txn.type === GpTransactionType.Payment || Money.isPos(txn.fee)) &&
                        Money.format(txn.fee)}
                    </Td>

                    <Td className="text-right">{Money.format(txn.net)}</Td>

                    <Td>
                      {txn.storeOrder?.request && (
                        <Link.T to={`/rfqs/${txn.storeOrder.request.id}`}>
                          {Id.shorten(txn.storeOrder.id)}
                        </Link.T>
                      )}
                    </Td>
                  </Tr>
                ))}

                {!paginatedTxns &&
                  times(limit).map((i) => (
                    <tr key={`ghost-${i}`}>
                      {times(ths.length).map((j) => (
                        <Td key={`ghost-${i}-${j}`}>
                          <Ghost />
                        </Td>
                      ))}
                    </tr>
                  ))}
              </Tbody>
            </table>
          </div>

          <div className="flex justify-between items-center px-6 py-4 border-t-1 border-gray-200">
            <div className="text-sm">
              {paginatedTxns ? (
                <>
                  <span className="font-medium">{paginatedTxns?.totalCount}</span> total
                </>
              ) : (
                <Ghost className="w-20" />
              )}
            </div>

            <div className="flex gap-2 items-center">
              <Pager path={prevPath}>Previous</Pager>
              <Pager path={nextPath}>Next</Pager>
            </div>
          </div>
        </div>
      </div>
    </Frame>
  )
}

export default Wallet
