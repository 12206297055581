import Frame from '@/dealers/components/Frame'
import useSession from '@/dealers/hooks/useSession'
import Page from '@/gf/components/Page'
import { LinkTabs } from '@/gf/components/next/Tabs'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/gf/components/next/Tooltip'
import useMsgs from '@/gf/hooks/useMsgs'
import Id from '@/gf/modules/Id'
import Money from '@/gf/modules/Money'
import { useApolloClient } from '@apollo/client'
import { ExclamationIcon, GlobeAltIcon } from '@heroicons/react/outline'
import { PencilIcon } from '@heroicons/react/solid'
import { useEffect, useMemo } from 'react'
import { Route, Routes, useLocation, useParams } from 'react-router-dom'
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params'
import NeededBy from '../../gf/components/NeededBy'
import TimeOpen from '../../gf/components/TimeOpen'
import {
  DeliveryMethod,
  RequestForQuoteStep,
  SubmittedQuoteDocument,
  TimelineEventsDocument,
  useRequestForQuoteQuery,
  useRequestUnreadMessagesQuery,
} from '../_gen/gql'
import RfqStepBadgeNext from '../components/RfqStepBadgeNext'
import useQuoteContext from '../hooks/useQuoteContext'
import useRfqViewedReceipt from '../hooks/useRfqViewedReceipt'
import Urls from '../modules/Urls'
import CreateShipmentModal from './Order/Details/CreateShipmentModal'
import ReadyForPickupModal from './Order/Details/ReadyForPickupModal'
import AcceptQuoteModal from './Request/AcceptQuoteModal'
import AssignUser from './Request/AssignUser'
import Automations from './Request/Automations'
import CustomerDetails from './Request/CustomerDetails'
import Details from './Request/Details'
import Messages from './Request/Messages'
import QuoteUploadProvider from './Request/QuoteUpload/QuoteUploadProvider'
import SendInvoiceModal from './Request/SendInvoiceModal'
import Timeline from './Request/Timeline'
import UpdateQuoteNumberModal from './Request/UpdateQuoteNumberModal'
import UpdateSpecialOrderNumberModal from './Request/UpdateSpecialOrderNumberModal'
import { RfqContext } from './Request/context'
import useModal, { ModalTypes } from './Request/useModal'

const TitleInfo = ({ label, children }) => (
  <div className="flex flex-col gap-y-1">
    <div className="font-medium text-base">{label}</div>
    <div className="flex-grow flex items-center text-base">{children}</div>
  </div>
)

const zeroPrice = Money.fromInt(0, 'USD')

const Request = () => {
  const { rfqId } = useParams<{ rfqId: string }>() as { rfqId: string }
  const { featureFlags, store, user } = useSession()
  const [isEditing, setIsEditing] = useQueryParam('edit', withDefault(BooleanParam, false))
  const { pathname } = useLocation()
  const gqlClient = useApolloClient()
  const modal = useModal()
  const [_, msgs] = useMsgs()
  useQuoteContext()
  useRfqViewedReceipt()

  const {
    data,
    refetch: refetchRequestForQuote,
    loading,
  } = useRequestForQuoteQuery({ variables: { id: rfqId, storeId: store.id } })

  const rfq = data?.requestForQuote

  useEffect(() => {
    if (isEditing && rfq && rfq.step !== RequestForQuoteStep.Quoted) setIsEditing(undefined)
  }, [!rfq])

  const refetch = () =>
    Promise.all([
      refetchRequestForQuote(),
      gqlClient.refetchQueries({ include: [TimelineEventsDocument, SubmittedQuoteDocument] }),
    ])

  const { data: unreadMessagesData } = useRequestUnreadMessagesQuery({
    variables: { rfqId },
    pollInterval: 10000,
  })

  const hasUnreadMessages = unreadMessagesData
    ? unreadMessagesData.conversations.pagination.totalResults > 0
    : false

  const title =
    store.customizedRequestLabel && rfq?.quote?.quoteNumber
      ? `Request ${rfq.quote.quoteNumber}`
      : `Request ${Id.shorten(rfqId)}`

  const tabs = useMemo(
    () => [
      {
        name: isEditing
          ? 'Update Quote'
          : !rfq?.quote
          ? 'Quote Builder'
          : rfq.step === RequestForQuoteStep.Quoted
          ? 'Your Quote'
          : 'Your Order',
        to: '',
        isCurrent: pathname === Urls.requestForQuote(rfqId),
      },
      {
        name: 'Customer Details',
        to: 'customer-details',
        isCurrent: pathname === Urls.requestForQuoteCustomerDetails(rfqId),
      },
      {
        name: 'Timeline',
        to: 'timeline',
        isCurrent: pathname === Urls.requestForQuoteTimeline(rfqId),
      },
      ...(user.claimed
        ? [
            {
              name: 'Automations',
              to: 'automations',
              isCurrent: pathname.indexOf('/automations') > -1,
            },
          ]
        : []),
      {
        name: 'Messages',
        to: 'messages',
        isCurrent: pathname.indexOf('/messages') > -1,
        showNotification: hasUnreadMessages,
      },
    ],
    [rfqId, isEditing, rfq?.quote, pathname, hasUnreadMessages, user.claimed]
  )

  const breadcrumbs = {
    copy: 'Back to Dashboard',
    crumbs: [
      { name: 'Requests', href: Urls.requestForQuotes() },
      { name: title, href: Urls.requestForQuote(rfqId) },
    ],
  }

  if (!rfq) return null

  const rfqMachine = rfq.machines?.[0] ?? null
  const someBackordered = rfq.quote?.lineItems.some((li) => !li.inStock)
  const refundTotal = rfq.quote?.refunds.map((r) => r.amount).reduce(Money.add, zeroPrice)

  return (
    <QuoteUploadProvider rfqId={rfqId}>
      <RfqContext.Provider value={{ rfqId, rfq, refetch }}>
        {rfq.quote && modal.current === ModalTypes.READY_FOR_PICKUP && (
          <ReadyForPickupModal
            open
            onClose={modal.close}
            storeOrderId={rfq.quote.id}
            onSuccess={() => {
              msgs.add('Order Ready fo Pickup!', 'positive')
              refetch()
            }}
          />
        )}

        {rfq.quote && modal.current === ModalTypes.CREATE_SHIPPING && (
          <CreateShipmentModal
            open
            onClose={modal.close}
            storeOrderId={rfq.quote.id}
            onSuccess={() => {
              msgs.add('Order Shipped!', 'positive')
              refetch()
            }}
          />
        )}

        {rfq.quote && modal.current === ModalTypes.SEND_INVOICE && (
          <SendInvoiceModal
            open
            onClose={modal.close}
            storeOrderId={rfq.quote.id}
            onSuccess={refetch}
          />
        )}

        {rfq.quote && (
          <UpdateSpecialOrderNumberModal
            open={modal.current === ModalTypes.UPDATE_SPECIAL_ORDER_NUMBER}
            onClose={modal.close}
            storeOrderId={rfq.quote.id}
            specialOrderNumber={rfq.quote.specialOrderNumber}
            onSuccess={() => refetch().then(() => modal.close())}
          />
        )}

        {rfq.quote && (
          <UpdateQuoteNumberModal
            open={modal.current === ModalTypes.UPDATE_QUOTE_NUMBER}
            onClose={modal.close}
            storeOrderId={rfq.quote.id}
            quoteNumber={rfq.quote.quoteNumber}
            onSuccess={() => refetch().then(() => modal.close())}
          />
        )}

        {rfq.quote && (
          <AcceptQuoteModal
            open={modal.current === ModalTypes.ACCEPT_QUOTE}
            onClose={modal.close}
            storeOrderId={rfq.quote.id}
            onSuccess={() => refetch().then(() => modal.close())}
          />
        )}

        <Frame breadcrumbs={breadcrumbs}>
          <Page className="max-w-[87.5rem] mx-auto w-full pt-8">
            <div className="flex-grow flex flex-col pb-12">
              <div className="flex flex-col gap-y-2">
                <div className="flex flex-wrap gap-3 justify-between items-center">
                  <div className="flex flex-row flex-nowrap gap-x-3 items-center">
                    <h2 className="flex shrink-0 flex-row flex-nowrap gap-x-3 items-center">
                      {rfq.user.organization.logoUrl && (
                        <img
                          src={rfq.user.organization.logoUrl}
                          className="h-[2.8125rem] w-[2.8125rem] rounded-full overflow-hidden object-contain bg-white p-px"
                          alt={`${rfq.user.organization.name} logo`}
                        />
                      )}
                      <div className="text-2xl font-medium leading-tight max-w-lg">
                        {rfq.user.organization.name}
                      </div>
                    </h2>

                    {/* Request details is the RFQ Vendor link */}
                    {!rfq.requestDetails && (
                      <Tooltip>
                        <TooltipTrigger>
                          <div className="p-1 inline-flex bg-gray-200 border border-gray-300 rounded-full">
                            <GlobeAltIcon className="w-4 h-4 inline-flex shrink-0 text-gray-700" />
                          </div>
                        </TooltipTrigger>

                        <TooltipContent className="max-w-prose p-3 z-50 bg-gray-50 border border-gray-300 rounded shadow-sm text-sm text-gray-900">
                          This request is from the Gearflow network.
                        </TooltipContent>
                      </Tooltip>
                    )}
                  </div>

                  <div className="flex flex-wrap flex-row gap-x-4 justify-end items-center text-sm text-gray-900 whitespace-nowrap">
                    {rfq.quote?.id && <div>Order ID: {Id.shorten(rfq.quote.id)}</div>}

                    {featureFlags.specialOrderNumber && rfq.quote && someBackordered && (
                      <div className="flex gap-1 items-center">
                        <span>
                          Special Order:{' '}
                          {rfq.quote.specialOrderNumber || (
                            <span className="italic text-gray-500">(not set)</span>
                          )}
                        </span>

                        <button
                          type="button"
                          className="p-1 text-gray-700 flex items-center rounded-full group hover:bg-gray-200 transition duration-75"
                          onClick={() => modal.open(ModalTypes.UPDATE_SPECIAL_ORDER_NUMBER)}
                        >
                          <PencilIcon className="h-5 w-5 flex shrink-0 text-gray-700" />
                        </button>
                      </div>
                    )}

                    {rfq.quote && (
                      <div className="flex gap-1 items-center">
                        <span>
                          Quote Number:{' '}
                          {rfq.quote.quoteNumber || (
                            <span className="italic text-gray-500">(not set)</span>
                          )}
                        </span>

                        <button
                          type="button"
                          className="p-1 text-gray-700 flex items-center rounded-full group hover:bg-gray-200 transition duration-75"
                          onClick={() => modal.open(ModalTypes.UPDATE_QUOTE_NUMBER)}
                        >
                          <PencilIcon className="h-5 w-5 flex shrink-0 text-gray-700" />
                        </button>
                      </div>
                    )}

                    {rfq.quote?.purchaseOrder && <div>PO Number: {rfq.quote.purchaseOrder}</div>}
                  </div>
                </div>

                <div className="flex flex-wrap justify-between items-center gap-8">
                  <div className="flex flex-row items-start gap-8">
                    <TitleInfo label="Time Open">
                      <TimeOpen
                        insertedAt={rfq.insertedAt}
                        completedAt={
                          rfq.quote?.timeline.outForDeliveryAt ??
                          rfq.quote?.timeline.readyForPickupAt ??
                          rfq.quote?.timeline.shippedAt ??
                          undefined
                        }
                        showIcon={false}
                      />
                    </TitleInfo>

                    {rfq.neededBy && (
                      <TitleInfo label="Urgency">
                        <div className="inline-flex items-center gap-x-2">
                          {rfqMachine?.machineDown && (
                            <Tooltip>
                              <TooltipTrigger>
                                <span className="p-1 flex items-center justify-center border border-red-200 bg-red-100 rounded-full">
                                  <ExclamationIcon className="h-4 w-4 flex shrink-0 text-red-700" />
                                </span>
                              </TooltipTrigger>
                              <TooltipContent className="z-[5000] max-w-xs p-3 bg-gray-50 border border-gray-300 rounded shadow-sm text-sm text-gray-900">
                                Machine down
                              </TooltipContent>
                            </Tooltip>
                          )}
                          <NeededBy date={rfq.neededBy} prefix="Needed " />
                        </div>
                      </TitleInfo>
                    )}
                  </div>

                  <div className="flex flex-row items-start gap-8">
                    <TitleInfo label="Status">
                      <div className="-mb-0.5">
                        <RfqStepBadgeNext step={rfq.step} />
                      </div>
                    </TitleInfo>

                    {rfq.quote && refundTotal && Money.isPos(refundTotal) && (
                      <TitleInfo label="Payment">
                        <div className="px-2 py-1 rounded border font-medium text-sm bg-red-100 border-red-200 text-red-700">
                          {Money.compare(refundTotal, rfq.quote.totals.total) === 0
                            ? 'Fully'
                            : 'Partially'}{' '}
                          Refunded
                        </div>
                      </TitleInfo>
                    )}

                    <TitleInfo label="Method">
                      {(rfq.quote?.deliveryMethod ?? rfq.requestDetails?.deliveryMethod) ===
                      DeliveryMethod.Pickup
                        ? 'Will Call'
                        : 'Delivery'}
                    </TitleInfo>

                    <TitleInfo label="Owner">
                      <div className="-mb-1">
                        <AssignUser
                          assignedUser={rfq.assignedUser}
                          claimedAccount={user.claimed ?? false}
                          onAssignedUserChanged={refetch}
                          rfqId={rfqId}
                        />
                      </div>
                    </TitleInfo>
                  </div>
                </div>
              </div>

              <div className="flex flex-col flex-grow mt-8">
                <nav className="flex gap-x-4 lg:gap-x-6 px-6 overflow-x-scroll">
                  <LinkTabs
                    tabs={tabs}
                    selectedTabName={tabs.find((t) => t.isCurrent)?.name as string}
                    color="blue"
                    textSize="base"
                  />
                </nav>

                <Routes>
                  <Route path="*" element={<Details loading={loading} editMode={isEditing} />} />
                  <Route path="customer-details" element={<CustomerDetails />} />
                  <Route path="timeline" element={<Timeline />} />
                  <Route path="messages/:conversationId?" element={<Messages rfq={rfq} />} />
                  <Route
                    path="messages/customer"
                    element={<Messages rfq={rfq} customerSelected />}
                  />
                  <Route path="messages/support" element={<Messages rfq={rfq} adminSelected />} />

                  <Route path="automations" element={<Automations />} />
                </Routes>
              </div>
            </div>
          </Page>
        </Frame>
      </RfqContext.Provider>
    </QuoteUploadProvider>
  )
}

export default Request
