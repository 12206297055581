import { useMemo } from 'react'
import classNames from 'classnames'
import pluralize from 'pluralize'
import {
  CheckCircleIcon,
  PencilIcon,
  ReceiptRefundIcon,
  XCircleIcon,
  XIcon,
} from '@heroicons/react/outline'

import {
  DeliveryMethod,
  PaymentMethod,
  RequestForQuoteStep,
  Shipment,
  StoreOrderState,
} from '@/dealers/_gen/gql'
import Urls from '@/dealers/modules/Urls'
import A from '@/gf/components/A'
import Box from '@/gf/components/Box'
import ImageGallery from '@/gf/components/ImageGallery'
import Link from '@/gf/components/Link'
import MoneyM from '@/gf/modules/Money'
import StoreOrderM from '@/gf/modules/StoreOrder'
import Address from '../Address'
import { useRfqContext } from '../context'
import MachineInformation from '../MachineInformation'
import SidebarLayout from '../SidebarLayout'
import Summary from '../Summary'
import { calcRefundableAmount } from './Refund'
import LineItems from './ViewQuote/LineItems'
import Time from '@/gf/modules/Time'
import Dot from '@/gf/components/Dot'

const ShipmentLabel = ({
  shipment,
}: {
  shipment: Pick<Shipment, 'shippingCarrier' | 'trackingNumber'>
}) =>
  shipment.shippingCarrier || shipment.trackingNumber ? (
    <span>
      {shipment.shippingCarrier} {shipment.trackingNumber}
    </span>
  ) : (
    <span className="text-gray-500">No tracking information provided</span>
  )

const zeroPrice = MoneyM.fromInt(0, 'USD')

const Section = ({ children, className = '' }) => (
  <div className={classNames('py-6 first:pt-0 last:pb-0', className)}>{children}</div>
)

const ViewQuote = () => {
  const { rfq } = useRfqContext()

  const showRefundAction = rfq.quote?.refundable && MoneyM.isPos(calcRefundableAmount(rfq.quote))
  const showUpdateQuoteAction =
    rfq.step === RequestForQuoteStep.Quoted || rfq.quote?.state === StoreOrderState.QuoteDenied
  const showCloseQuoteAction =
    rfq.step === RequestForQuoteStep.Quoted || rfq.quote?.state === StoreOrderState.QuoteDenied

  const [approvedLineItems, rejectedLineItems] = useMemo(
    () => [
      rfq.quote?.lineItems.filter((li) => !li.rejectedAt) ?? [],
      rfq.quote?.lineItems.filter((li) => !!li.rejectedAt) ?? [],
    ],
    [rfq.quote]
  )

  if (!rfq.quote) return null

  const { quote } = rfq

  return (
    <SidebarLayout className="space-y-4 flex-grow">
      <Box className="shadow-base flex-grow p-6 flex flex-col gap-y-6">
        {(showUpdateQuoteAction || showCloseQuoteAction || showRefundAction) && (
          <div className="w-full flex flex-row flex-wrap justify-between items-center gap-x-2">
            {showUpdateQuoteAction && (
              <Link.S
                className="flex justify-center items-center gap-x-1 text-gray-900"
                to={`${Urls.requestForQuote(rfq.id)}?edit=1`}
              >
                <PencilIcon className="w-5 h-5 flex shrink-0" />
                Update Quote
              </Link.S>
            )}

            {showCloseQuoteAction && (
              <Link.S
                className="flex justify-center items-center gap-x-1 text-gray-900"
                to="close-quote"
              >
                <XIcon className="w-5 h-5 flex shrink-0" />
                Close Quote
              </Link.S>
            )}

            {showRefundAction && (
              <Link.S
                className="flex justify-center items-center gap-x-1 text-gray-900"
                to={`/rfqs/${rfq.id}/refund`}
                key="refund"
              >
                <ReceiptRefundIcon className="w-5 h-5 flex shrink-0" />
                Refund
              </Link.S>
            )}
          </div>
        )}

        <div className="divide-y-1">
          {quote.deliveryMethod !== DeliveryMethod.Pickup && quote.shipments.length > 0 && (
            <Section className="space-y-2">
              <h4 className="text-lg font-medium">Shipments</h4>

              <div className="flex flex-row flex-wrap gap-x-4 gap-y-2 text-gray-700">
                {quote.shipments.map((shipment) => (
                  <div
                    key={shipment.id}
                    className="px-4 py-2 flex flex-col gap-y-1 justify-between text-base bg-gray-50 rounded-lg"
                  >
                    <div className="flex flex-row items-center gap-x-2">
                      <span className="font-medium">
                        {Time.formatDateWithDaySmartYear(shipment.shippedAt)}
                      </span>
                      <Dot className="bg-gray-700" />
                      <Link.T
                        to={`/rfqs/${rfq.id}/shipments/${shipment.id}`}
                        className="flex whitespace-nowrap text-gray-700"
                      >
                        {pluralize(
                          'part',
                          shipment.items.reduce((acc, i) => acc + i.quantity, 0),
                          true
                        )}
                      </Link.T>
                    </div>
                    {shipment.trackingLink ? (
                      <A.T className="text-base" href="" target="_blank">
                        <ShipmentLabel shipment={shipment} />
                      </A.T>
                    ) : (
                      <span>
                        <ShipmentLabel shipment={shipment} />
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </Section>
          )}

          <Section>
            <LineItems
              lineItems={approvedLineItems}
              title={
                rejectedLineItems.length > 0 ? (
                  <>
                    <CheckCircleIcon className="w-5 h-5 text-green-500" /> Approved Parts
                  </>
                ) : (
                  'Parts'
                )
              }
              deliveryMethod={quote.deliveryMethod}
            />

            {rejectedLineItems.length === 0 && (
              <Summary
                className="max-w-[21rem] mt-4 py-2"
                total={quote.totals.total}
                subtotal={MoneyM.add(quote.totals.subtotal, quote.totals.discount)}
                discountPercent={quote.discounts.reduce(
                  (sum: number, discount: { percent: number }) => sum + discount.percent,
                  0
                )}
                discount={quote.totals.discount}
                shippingCost={quote.shippingAmount}
                taxCost={
                  rfq.requestDetails || !MoneyM.isZero(quote.salesTax) ? quote.salesTax : null
                }
                customerFee={quote.totals.customerFee}
                refunded={quote.refunds.map((r) => r.amount).reduce(MoneyM.add, zeroPrice)}
                showShippingCost={quote.deliveryMethod === DeliveryMethod.Shipping}
                paidWithPaymentMethod={
                  StoreOrderM.isQuoteApproved(quote)
                    ? quote.paymentMethod === PaymentMethod.Direct
                      ? { accountNumber: quote.payingAccountNumber ?? '' }
                      : 'gearflow_payments'
                    : undefined
                }
              />
            )}
          </Section>

          {rfq.images.length > 0 && (
            <Section>
              <ImageGallery images={rfq.images.map((i) => i.url)} />
            </Section>
          )}

          {quote.deliveryMethod !== DeliveryMethod.Pickup && quote.shippingAddress && (
            <Section>
              <Address address={quote.shippingAddress} textSize="md" title="Shipping Address" />
            </Section>
          )}

          {quote.pickupAddress && (
            <Section>
              <Address address={quote.pickupAddress} textSize="md" title="Will Call Location" />
            </Section>
          )}

          <Section>
            <MachineInformation reqMachine={rfq.machines[0] ?? null} textSize="base" />

            {rfq.partsRequest && (
              <p className="leading-6 prose whitespace-pre-wrap mt-6">
                Comments: {rfq.partsRequest}
              </p>
            )}
          </Section>

          {quote.timingDetails && quote.timingDetails !== '' && (
            <Section>
              <h4 className="font-medium text-lg text-gray-900">Expected lead time</h4>
              <p className="leading-6 prose whitespace-pre-wrap">{quote.timingDetails}</p>
            </Section>
          )}
        </div>
      </Box>

      {rejectedLineItems.length > 0 && (
        <Box className="shadow-base flex-grow p-6 flex flex-col gap-y-6 bg-gray-50">
          <LineItems
            lineItems={rejectedLineItems}
            title={
              <>
                <XCircleIcon className="w-5 h-5 text-red-500" /> Rejected Parts
              </>
            }
            subtitle="The part(s) below have been removed from your updated quote."
            deliveryMethod={quote.deliveryMethod}
            className="border-b border-gray-200"
          />

          <div>
            <h4 className="text-lg font-medium">Rejected Reason</h4>
            <p className="text-base mt-2">
              {quote.timeline.denialReason}
              {quote.timeline.denialAdditionalComments && (
                <>
                  <br />“{quote.timeline.denialAdditionalComments}”
                </>
              )}
            </p>
          </div>
        </Box>
      )}
    </SidebarLayout>
  )
}

export default ViewQuote
