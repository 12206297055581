import InboxBase from '@/dealers/components/Inbox'
import { Conversation, ConversationScheduledMessage } from '@/dealers/components/Inbox/types'
import Page from '@/gf/components/Page'
import SearchInput from '@/gf/components/SearchInput'
import { trackSearch } from '@/gf/modules/Analytics'
import Id from '@/gf/modules/Id'
import { Maybe } from '@/types'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import {
  ConversationMessagesDocument,
  ConversationsDocument,
  ConversationsQuery,
  useCreateConversationMutation,
  useCreateMessageMutation,
  useReadReceiptsQuery,
  useRequestScheduledMessagesLazyQuery,
  useUnreadMessagesQuery,
} from '../_gen/gql'
import Frame from '../components/Frame'
import useConversations from '../hooks/useConversations'
import useMessagesForConversation from '../hooks/useMessagesForConversation'
import ConversationM from '../modules/Conversation'
import RequestForQuoteM from '../modules/RequestForQuote'
import StoreOrderM from '../modules/StoreOrder'
import Urls from '../modules/Urls'
import InboxDetails from './Inbox/InboxDetails'

const displayCustomerContactInfo = (
  conversation: ConversationsQuery['conversations']['entries'][number]
) =>
  conversation.requestForQuote
    ? RequestForQuoteM.canDisplayCustomerContactInfo({
        step: conversation.requestForQuote.step,
        user: {
          isCustomerContact: conversation.requestForQuote.user.isCustomerContact,
        },
        storeOrder: conversation.requestForQuote.quote
          ? {
              state: conversation.requestForQuote.quote.state,
            }
          : null,
      })
    : conversation.storeOrder
    ? StoreOrderM.canDisplayCustomerContactInfo({
        state: conversation.storeOrder.state,
        user: { isCustomerContact: conversation.storeOrder.customer.isCustomerContact },
      })
    : false

const inboxTitle = (conversation: ConversationsQuery['conversations']['entries'][number]) =>
  conversation.requestForQuote
    ? [
        `Request ${Id.shorten(conversation.requestForQuote.id)}`,
        ...(conversation.requestForQuote.quote
          ? [`Order ${Id.shorten(conversation.requestForQuote.quote.id)}`]
          : []),
      ]
    : conversation.storeOrder
    ? `Order ${Id.shorten(conversation.storeOrder.id)}`
    : ''

const conversationName = (conversation: ConversationsQuery['conversations']['entries'][number]) =>
  conversation.admin
    ? ConversationM.ADMIN_CONVERSATION_NAME
    : conversation.requestForQuote
    ? ConversationM.conversationNameForRfq(
        conversation.requestForQuote,
        RequestForQuoteM.canDisplayCustomerContactInfo({
          step: conversation.requestForQuote.step,
          user: {
            isCustomerContact: conversation.requestForQuote.user.isCustomerContact,
          },
          storeOrder: conversation.requestForQuote.quote
            ? {
                state: conversation.requestForQuote.quote.state,
              }
            : null,
        })
      )
    : conversation.storeOrder
    ? ConversationM.conversationNameForStoreOrder(
        conversation.storeOrder,
        StoreOrderM.canDisplayCustomerContactInfo({
          state: conversation.storeOrder.state,
          user: { isCustomerContact: conversation.storeOrder.customer.isCustomerContact },
        })
      )
    : ''

const Inbox = () => {
  const navigate = useNavigate()
  const { conversationId } = useParams()
  const [scheduledMessage, setScheduledMessage] =
    useState<Maybe<ConversationScheduledMessage>>(null)

  const [searchTerm, setSearchTerm] = useState('')
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1))
  const [fetchScheduledMessages] = useRequestScheduledMessagesLazyQuery()
  const { refetch: refetchUnreadMessages } = useUnreadMessagesQuery()

  const { data, refetch: refetchConversations } = useConversations({
    filters: [...(searchTerm ? [['contains', 'search', searchTerm]] : [])],
    page: 1,
    pageSize: 30,
    onCompleted: (resultData) => {
      if (searchTerm) {
        trackSearch(searchTerm, 'inbox_page', {
          resultsFound: resultData.conversations.pagination.totalResults,
        })
      }
    },
  })

  const conversationsById = useMemo(() => {
    if (!data?.entries) return {}

    return data.entries.reduce(
      (all, conversation) => ({ ...all, [conversation.id]: conversation }),
      {}
    )
  }, [data?.entries])

  const [createMessage] = useCreateMessageMutation({
    refetchQueries: [ConversationMessagesDocument, ConversationsDocument],
  })

  const [createConversation] = useCreateConversationMutation({
    refetchQueries: [ConversationsDocument],
  })

  const onSelectedConversationIdChange = (selectedConversationId: string | undefined) =>
    selectedConversationId
      ? navigate(Urls.inboxConversation(selectedConversationId))
      : navigate(Urls.inbox())

  const createInboxDetails = useCallback((props) => <InboxDetails {...props} />, [])

  const onSearchChanged = useMemo(() => debounce(setSearchTerm, 300), [])

  const fetchScheduledMessageForConversation = async (requestForQuoteId) => {
    const result = await fetchScheduledMessages({ variables: { requestForQuoteId } })

    setScheduledMessage(
      result?.data?.scheduledMessages
        .filter((sm) => !sm.cancelledAt && !sm.sentAt)
        .map((sm) => ({
          ...sm,
          viewMorePath: Urls.requestForQuoteAutomations(requestForQuoteId),
        }))[0] ?? null
    )
  }

  useEffect(() => {
    if (conversationId && data) {
      setScheduledMessage(null)

      const conversation = data.entries.find((c) => c.id === conversationId)

      if (conversation?.requestForQuote?.id) {
        fetchScheduledMessageForConversation(conversation?.requestForQuote?.id)
      }
    }
  }, [data, conversationId])

  const conversations = useMemo(
    () =>
      data?.entries.map(
        (c) =>
          ({
            id: c.id,
            admin: c.admin,
            name: conversationName(c),
            lastMessage: c.lastMessage,
            unreadMessages: c.unreadMessages,
            storeId: c.store?.id,
            source: c.storeOrder
              ? {
                  type: 'store_order',
                  id: c.storeOrder.id,
                  user: c.storeOrder.customer,
                }
              : c.requestForQuote && {
                  type: 'request',
                  id: c.requestForQuote.id,
                  user: c.requestForQuote.user,
                },
            scheduledMessage: c.id === conversationId ? scheduledMessage : null,
          } as Conversation)
      ),
    [data, conversationId, scheduledMessage]
  )

  return (
    <Frame>
      <Page title="Inbox">
        <div className="mt-4 space-y-2">
          <SearchInput
            initialValue={searchTerm}
            onChange={onSearchChanged}
            placeholder="filter conversations by order id, request id or message content"
          />

          {searchTerm.length > 0 && conversations?.length === 0 && (
            <div className="prose text-sm">No messages found 🫤</div>
          )}
        </div>

        <InboxBase
          className="mt-4"
          conversations={conversations}
          selectedConversationId={conversationId}
          onSelectedConversationIdChange={onSelectedConversationIdChange}
          useMessagesForConversation={useMessagesForConversation}
          useReadReceiptsForConversation={useReadReceiptsQuery}
          refetchConversations={() =>
            Promise.all([refetchConversations(), refetchUnreadMessages()])
          }
          page={page}
          setPage={setPage}
          pagination={data?.pagination ?? { totalPages: 0, totalResults: 0 }}
          newConversations={[]}
          DetailsForConversation={createInboxDetails}
          displayCustomerContactInfo={(conversation) =>
            displayCustomerContactInfo(conversationsById[conversation.id])
          }
          conversationRowContent={(conversation) => ({
            name: inboxTitle(conversationsById[conversation.id]),
            details: { type: conversation.admin ? 'admin' : 'buyer', name: conversation.name },
          })}
          onCreateMessage={({ messageInput }) =>
            createMessage({
              variables: {
                conversationId: messageInput.conversationId,
                text: messageInput.text,
                attachmentUrls: messageInput.attachmentUrls ?? [],
              },
            })
          }
          onCreateConversation={async ({ conversationInput, messageText, attachmentUrls }) => {
            const { data: createConvoData } = await createConversation({
              variables: {
                conversation: {
                  requestForQuoteId: conversationInput.requestForQuoteId ?? null,
                  storeOrderId: conversationInput.storeOrderId ?? null,
                  storeId: conversationInput.storeId as string,
                  admin: conversationInput.admin,
                },
                attachmentUrls: attachmentUrls ?? null,
                messageText: messageText ?? null,
              },
            })

            return createConvoData?.createConversation
          }}
        />
      </Page>
    </Frame>
  )
}

export default Inbox
